export const pomodoro = [
    { name: 'Work', seconds: 25 * 60, elapsed: 0 },
    { name: 'Break', seconds: 5 * 60, elapsed: 0 },
    { name: 'Work', seconds: 25 * 60, elapsed: 0 },
    { name: 'Break', seconds: 5 * 60, elapsed: 0 },
    { name: 'Work', seconds: 25 * 60, elapsed: 0 },
    { name: 'Break', seconds: 5 * 60, elapsed: 0 },
    { name: 'Work', seconds: 25 * 60, elapsed: 0 },
    { name: 'Break', seconds: 15 * 60, elapsed: 0 },
  ];
  
  export const hourdoro = [
    { name: 'Warm up', seconds: 5 * 60, elapsed: 0 },
    { name: 'Get serious', seconds: 10 * 60, elapsed: 0 },
    { name: 'Deep focus', seconds: 15 * 60, elapsed: 0 },
    { name: 'Finish task!', seconds: 15 * 60, elapsed: 0 },
    { name: 'Break', seconds: 15 * 60, elapsed: 0 },
  ];
  
  export const jkflow = [
    { name: 'Struggle', seconds: 15 * 60, elapsed: 0 },
    { name: 'Relaxation', seconds: 5 * 60, elapsed: 0 },
    { name: 'Flow', seconds: 45 * 60, elapsed: 0 },
    { name: 'Consolidation', seconds: 15 * 60, elapsed: 0 },
  ];